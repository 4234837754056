/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Armutlu Hacı Ali Paşa Camii'nim yapım kitabesi günümüze gelemediğinden yapım tarihi ve banisi hakkında kesin bilgi bulunmamaktadır. Bazı kaynaklarda bu caminin Orhan Gazi döneminde yapıldığı belirtilse de bunu doğrulayacak mimari bir kalıntıya rastlanılmamıştır. Bugünkü mimari üslubundan XIX. yüzyılda yapıldığı sanılan bu cami geç dönemde yapılan onarımlarla özelliğini büyük ölçüde yitirmiştir."), "\n", React.createElement(_components.p, null, "Cami moloz taştan dikdörtgen planlı olarak yapılmış, üzeri ahşap bir çatı ile örtülmüştür. XX. yüzyılın ikinci yarısında bütünü ile restore edilmiş ve bu restorasyon sırasında eski izleri yok edilmiştir. Yalnızca eski yapıya ait minare kaidesi çatı hizasına kadar ayakta durmaktadır. İbadet mekânı altlı üstlü her kenarda ikişer pencere ile aydınlatılmıştır. Bunlardan alt sıradakiler dikdörtgen söveli, üst sıradakiler ise alçı şebekelidir. Mihrap ve minberinin herhangi bir özelliği bulunmamaktadır."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
